<template>
  <div class="ow-navbar">
    <div class="ow-hamburger-menu" :class="{ 'is-expanded': menuOpen }">
      <div class="ow-hamburger-menu-icon-container" :class="{ 'menu-open': menuOpen }" >
        <input type="checkbox" id="ow-hamburger-menu-input" v-model="menuOpen" >
        <label class="ow-hamburger-menu-icon" for="ow-hamburger-menu-input" @click="toggleMenu">
          <div></div>
          <div></div>
          <div></div>
        </label>
      </div>
      <nav class="ow-hamburger-menu-items" :class="{ 'is-open': menuOpen }">
        <div class="ow-hamburger-menu-logo-wrapper">
          <img src="/images/OxiaLogoAllWhite.png" alt="Logo" class="ow-hamburger-menu-logo" @click="navbarItemClicked('/', true)" />
        </div>
        <div class="ow-hamburger-menu-item"
          :class="{'is-active': isActiveTab('patient')}"
          @click="navbarItemClicked('patient')"
          >
          Patient
        </div>
        <div class="ow-hamburger-menu-item has-nested-list"
          :class="{'is-active': hasActiveTab(['/ophthalmologist', '/physician', '/general-practitioner', '/optometrist', '/pharmacy', '/independent-screening-centre', '/other'])}"
          >
          <input type="checkbox" class="ow-hamburger-menu-item-nested-list-checkbox-input" id="professionals-checkbox">
          <label class="ow-hamburger-menu-item-nested-list-dropdown-label" for="professionals-checkbox">
            <span>Professionals</span>
            <div class="os-icon icon-down"></div>
          </label>
          <ul class="ow-hamburger-menu-item-nested-list">
            <div class="ow-hamburger-menu-item-nested-list-item"
              :class="{'is-active': isActiveTab('ophthalmologist')}"
              @click="navbarItemClicked('ophthalmologist')"
              >
              Ophthalmologist
            </div>
            <div class="ow-hamburger-menu-item-nested-list-item"
              :class="{'is-active': isActiveTab('physician')}"
              @click="navbarItemClicked('physician')"
              >
              Physician
            </div>
            <div class="ow-hamburger-menu-item-nested-list-item"
              :class="{'is-active': isActiveTab('general-practitioner')}"
              @click="navbarItemClicked('general-practitioner')"
              >
              General Practitioner
            </div>
            <div class="ow-hamburger-menu-item-nested-list-item"
              :class="{'is-active': isActiveTab('optometrist')}"
              @click="navbarItemClicked('optometrist')"
              >
              Optometrist
            </div>
            <div class="ow-hamburger-menu-item-nested-list-item"
              :class="{'is-active': isActiveTab('pharmacy')}"
              @click="navbarItemClicked('pharmacy')"
              >
              Pharmacy
            </div>
            <div class="ow-hamburger-menu-item-nested-list-item"
              :class="{'is-active': isActiveTab('independent-screening-centre')}"
              @click="navbarItemClicked('independent-screening-centre')"
              >
              Independent Screening Centre
            </div>
            <div class="ow-hamburger-menu-item-nested-list-item"
              :class="{'is-active': isActiveTab('other')}"
              @click="navbarItemClicked('other')"
              >
              Other
            </div>
          </ul>
        </div>
        <div class="ow-hamburger-menu-item"
          :class="{'is-active': isActiveTab('employee-wellness')}"
          @click="navbarItemClicked('employee-wellness')"
          >
          Employee Wellness
        </div>
        <!--  <div class="ow-hamburger-menu-item"
          :class="{'is-active': isActiveTab('questions-and-answers')}"
          @click="navbarItemClicked('questions-and-answers')"
          >
          Q&A
        </div> -->
      </nav>
    </div>
    <div class="ow-navbar-logo-wrapper">
      <span @click="navbarItemClicked('/', true)" class="ow-navbar-logo-link">
        <img src="/images/OxiaNavbarLogo.png" class="ow-navbar-logo" />
      </span>
    </div>
  </div>
</template>


<script setup lang="ts">

import { ref, computed, watch } from 'vue';
import { useRouter} from 'vue-router';

const router = useRouter();

const currentRoute = computed(() => {
  return router.currentRoute.value.path;
});

const menuOpen = ref(false);

const isMobile = computed(() => {
  if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) return true
  return false;
})

const isActiveTab = (tabRoute: string) => {
  return `/${tabRoute}` === currentRoute.value;
}

const hasActiveTab = (tabRouteList: string[]) => {
  return tabRouteList.includes(currentRoute.value);
}

const navbarItemClicked = (to:string , shouldCloseMenu?: boolean) => {
  router.push(to);
  if (shouldCloseMenu) menuOpen.value = false;
};

watch(() => currentRoute.value, (newVal) => {
  if (!isMobile.value) {
    setTimeout(() => {
      if (newVal !== '/') menuOpen.value = true;
      else menuOpen.value = false;
    }, 800);
  }
  else menuOpen.value = false;
} )
</script>